<template>
  <div class="app-container">
    <el-form v-show="showSearch" ref="queryForm" :inline="true" :model="queryParams" label-width="68px">
      <el-form-item label="小程序id" prop="appid">
        <el-input
          v-model="queryParams.appid"
          clearable
          placeholder="请输入小程序id"
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="小程序名字" prop="name">
        <el-input
          v-model="queryParams.name"
          clearable
          placeholder="请输入小程序名字"
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

      <el-form-item v-if="this.username=='root'" label="所属账号" prop="accountId">
        <el-input
          v-model="queryParams.accountId"
          clearable
          placeholder="请输入所属账号"
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
<!--      <el-form-item label="管理员" prop="admin">-->
<!--        <el-input-->
<!--          v-model="queryParams.admin"-->
<!--          clearable-->
<!--          placeholder="请输入管理员"-->
<!--          size="small"-->
<!--          @keyup.enter.native="handleQuery"-->
<!--        />-->
<!--      </el-form-item>-->
<!--      <el-form-item label="管理员微信" prop="adminWx">-->
<!--        <el-input-->
<!--          v-model="queryParams.adminWx"-->
<!--          clearable-->
<!--          placeholder="请输入管理员微信"-->
<!--          size="small"-->
<!--          @keyup.enter.native="handleQuery"-->
<!--        />-->
<!--      </el-form-item>-->
      <el-form-item label="主体" prop="belong">
        <el-input
          v-model="queryParams.belong"
          clearable
          placeholder="请输入主体"
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>

<!--      <el-form-item label="群聊所属微信" prop="groupWx">-->
<!--        <el-input-->
<!--          v-model="queryParams.groupWx"-->
<!--          clearable-->
<!--          placeholder="请输入群聊所属微信"-->
<!--          size="small"-->
<!--          @keyup.enter.native="handleQuery"-->
<!--        />-->
<!--      </el-form-item>-->
      <el-form-item>
        <el-button icon="el-icon-search" size="mini" type="primary" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          icon="el-icon-plus"
          plain
          size="mini"
          type="primary"
          @click="handleAdd"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          :disabled="single"
          icon="el-icon-edit"
          plain
          size="mini"
          type="success"
          @click="handleUpdate"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          :disabled="multiple"
          icon="el-icon-delete"
          plain
          size="mini"
          type="danger"
          @click="handleDelete"
        >删除</el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="WxappList" @selection-change="handleSelectionChange">
      <el-table-column align="center" type="selection" width="55" />
<!--      <el-table-column label="主键" align="center" prop="id" />-->
      <el-table-column align="center" label="小程序id" prop="appid" />
      <el-table-column align="center" label="小程序名字" prop="name" />
<!--      <el-table-column align="center" label="小程序类型（1微信2抖音3快手）" prop="type" />-->

<!--      <el-table-column align="center" label="首页展示名字" prop="indexShowName" />-->
<!--      <el-table-column align="center" label="内容页名字" prop="detailShowName" />-->
<!--      <el-table-column align="center" label="主题色" prop="theme" >-->
<!--        　<template slot-scope="scope">-->
<!--        　　　<el-color-picker v-model="scope.row.theme" show-alpha></el-color-picker>-->
<!--        　　</template>-->
<!--      </el-table-column>-->

<!--      <el-table-column align="center" label="隔几个内容跳转" prop="distanceToAppid" />-->

<!--      <el-table-column align="center" label="隔几个内容原生广告" prop="distanceToAdd" />-->
<!--      <el-table-column label="隔几个内容激励视频" align="center" prop="distanceToVad" />-->
<!--      <el-table-column align="center" label="是否强制入群(红色为关闭)" prop="isAd">-->
<!--        <template slot-scope="scoped">-->
<!--          <el-switch-->
<!--              v-model="scoped.row.isAd"-->
<!--              :active-value="1"-->
<!--              :inactive-value="0"-->
<!--              active-color="#13ce66"-->
<!--              inactive-color="#ff4949"-->
<!--              @change="changeInformationStatus($event, scoped.row, scoped.column)">-->
<!--          </el-switch>-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column align="center" label="强制加群图片" >-->

<!--          　<template slot-scope="scope">-->
<!--          　　　　<img :src="scope.row.addCode" height="40" width="40" />-->
<!--          　　</template>-->

<!--      </el-table-column>-->

<!--      <el-table-column align="center" label="分享图片" >-->

<!--        　<template slot-scope="scope">-->
<!--        　　　　<img :src="scope.row.shareImg" height="40" width="40" />-->
<!--        　　</template>-->

<!--      </el-table-column>-->

<!--      <el-table-column align="center" label="背景图" >-->
<!--        　<template slot-scope="scope">-->
<!--        　　　　<img :src="scope.row.backImg" height="40" width="40" />-->
<!--        　　</template>-->
<!--      </el-table-column>-->

<!--      <el-table-column align="center" label="生成图" >-->
<!--        　<template slot-scope="scope">-->
<!--        　　　　<img :src="scope.row.endImg" height="40" width="40" />-->
<!--        　　</template>-->
<!--      </el-table-column>-->

<!--      <el-table-column align="center" label="引流动图" >-->

<!--        　<template slot-scope="scope">-->
<!--        　　　　<img :src="scope.row.goCodeImg" height="40" width="60" />-->
<!--        　　</template>-->

<!--      </el-table-column>-->
<!--      <el-table-column align="center" label="强制加群音频" prop="audio" />-->
<!--      <el-table-column align="center" label="分享文案" prop="addText" />-->
<!--      <el-table-column align="center" label="群聊名称" prop="groupName" />-->
<!--      <el-table-column align="center" label="群聊所属微信" prop="groupWx" />-->

<!--      <el-table-column align="center" label="登录账户" prop="account" />-->
<!--      <el-table-column align="center" label="管理员" prop="admin" />-->
      <el-table-column align="center" label="管理员微信" prop="adminWx" />
      <el-table-column align="center" label="主体" prop="belong" />
<!--      <el-table-column align="center" label="审核状态" prop="status" />-->

      <el-table-column align="center" label="secret" prop="secret" />
      <el-table-column align="center" label="点击率（不填为不强点）" prop="clickRate" />
      <el-table-column align="center" label="三方广告ID（珊瑚）" prop="audio" />
      <el-table-column align="center" label="是否开始三方广告（1开启）" prop="theme" />
<!--      <el-table-column label="创建时间" align="center" prop="createTime" />-->
<!--      <el-table-column align="center" label="上线时间" prop="adoptTime" />-->
            <el-table-column v-if="this.username=='root'" align="center" label="页面推广路径" prop="spreadPath" />
            <el-table-column v-if="this.username=='root'" align="center" label="上架创作者平台（0否1是)" prop="distribution" />
      <el-table-column v-if="this.username=='root'" label="所属账号" align="center" prop="accountId" />
      <el-table-column align="center" class-name="small-padding fixed-width" label="操作">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            type="text"
            @click="handleUpdate(scope.row)"
          >修改</el-button>
          <el-button
            icon="el-icon-delete"
            size="mini"
            type="text"
            @click="handleDelete(scope.row)"
          >删除</el-button>

        <el-button
            icon="el-icon-edit"
            size="mini"
            type="text"
            @click="adConfig(scope.row)"
        >广告配置</el-button>
        </template>

      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :limit.sync="queryParams.pageSize"
      :page.sync="queryParams.pageNum"
      :total="total"
      @pagination="getList"
    />

    <!-- 添加或修改小程序管理对话框 -->
    <el-dialog :title="title" :visible.sync="open" append-to-body width="600px">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="小程序id" prop="appid" required="true">
          <el-input v-model="form.appid" placeholder="请输入小程序id" />
        </el-form-item>
        <el-form-item label="小程序名字" prop="name" required="true">
          <el-input v-model="form.name" placeholder="请输入小程序名字" />
        </el-form-item>
        <el-form-item label="小程序类型" prop="type" required="true">
          <el-input v-model="form.type" placeholder="请输入小程序名字1微信2抖音3快手" />
        </el-form-item>

<!--        <el-form-item label="首页展示名字" prop="indexShowName">-->
<!--          <el-input v-model="form.indexShowName" placeholder="首页展示名字（可不填，默认和小程序名字一致）" />-->
<!--        </el-form-item>-->
<!--        <el-form-item label="内容页名字" prop="detailShowName">-->
<!--          <el-input v-model="form.detailShowName" placeholder="内容页展示名字（可不填，默认和小程序名字一致）" />-->
<!--        </el-form-item>-->
<!--        <el-form-item label="主题" prop="detailShowName">-->
<!--&lt;!&ndash;          <el-input v-model="form.theme" placeholder="请输入主题" />&ndash;&gt;-->
<!--          <el-color-picker v-model="form.theme"></el-color-picker>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="隔几个内容跳转" prop="distanceToAppid">-->
<!--          <el-input v-model="form.distanceToAppid" placeholder="请输入隔几个内容跳转" />-->
<!--        </el-form-item>-->
<!--        <el-form-item label="隔几个内容原生广告" prop="distanceToAdd">-->
<!--          <el-input v-model="form.distanceToAdd" placeholder="请输入隔几个内容原生广告" />-->
<!--        </el-form-item>-->


<!--        <el-form-item label="强制加群图片" prop="addCode">-->
<!--          <el-input v-model="form.addCode" placeholder="和本地上传二选一" />-->
<!--          <template>-->
<!--            　<img :src="form.addCode" height="40" width="40" />-->
<!--          </template>-->
<!--          <el-upload-->
<!--              :action="uploadImgUrl"-->
<!--              :limit="1"-->
<!--              :on-exceed="handleExceed"-->
<!--              :on-success="handleAvatarSuccess"-->
<!--              class="upload-demo"-->
<!--              multiple-->
<!--             >-->
<!--            <el-button size="small" type="primary">本地上传</el-button>-->
<!--&lt;!&ndash;            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>&ndash;&gt;-->
<!--          </el-upload>-->
<!--&lt;!&ndash;          <el-input v-model="form.addCode" placeholder="请输入强制加群图片" />&ndash;&gt;-->
<!--        </el-form-item>-->

<!--        <el-form-item label="分享图片" prop="shareImg">-->
<!--          <el-input v-model="form.shareImg" placeholder="和本地上传二选一" />-->
<!--          <template>-->
<!--            　<img :src="form.shareImg" height="40" width="40" />-->
<!--          </template>-->
<!--          <el-upload-->
<!--              :action="uploadImgUrl"-->
<!--              :limit="1"-->
<!--              :on-exceed="handleExceed"-->
<!--              :on-success="handleAvatarSuccessShareImg"-->
<!--              class="upload-demo"-->
<!--              multiple-->
<!--          >-->
<!--            <el-button size="small" type="primary">本地上传</el-button>-->
<!--            &lt;!&ndash;            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>&ndash;&gt;-->
<!--          </el-upload>-->
<!--          &lt;!&ndash;          <el-input v-model="form.addCode" placeholder="请输入强制加群图片" />&ndash;&gt;-->
<!--        </el-form-item>-->

<!--        <el-form-item label="强制加群音频" prop="audio">-->
<!--          <el-input v-model="form.audio" placeholder="和本地上传二选一" />-->
<!--          <el-upload-->
<!--              :action="uploadImgUrl"-->
<!--              :limit="1"-->
<!--              :on-exceed="handleExceed"-->
<!--              :on-success="handleAvatarSuccessAudio"-->
<!--              class="upload-demo"-->
<!--              multiple-->
<!--          >-->
<!--            <el-button size="small" type="primary">本地上传</el-button>-->
<!--            &lt;!&ndash;            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>&ndash;&gt;-->
<!--          </el-upload>-->
<!--          &lt;!&ndash;          <el-input v-model="form.addCode" placeholder="请输入强制加群图片" />&ndash;&gt;-->
<!--        </el-form-item>-->

<!--        <el-form-item label="背景图" prop="backImg">-->
<!--          <el-input v-model="form.backImg" placeholder="和本地上传二选一" />-->
<!--          <template>-->
<!--            　<img :src="form.backImg" height="40" width="40" />-->
<!--          </template>-->
<!--          <el-upload-->
<!--              :action="uploadImgUrl"-->
<!--              :limit="1"-->
<!--              :on-exceed="handleExceed"-->
<!--              :on-success="handleBackImgSuccess"-->
<!--              class="upload-demo"-->
<!--              multiple-->
<!--          >-->
<!--            <el-button size="small" type="primary">点击上传</el-button>-->
<!--            &lt;!&ndash;            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>&ndash;&gt;-->
<!--          </el-upload>-->
<!--          &lt;!&ndash;          <el-input v-model="form.addCode" placeholder="请输入引流二维码" />&ndash;&gt;-->
<!--        </el-form-item>-->

<!--        <el-form-item label="生成图（可不传会自动生成）" prop="endImg">-->
<!--          <el-input v-model="form.endImg" placeholder="和本地上传二选一" />-->
<!--          <template>-->
<!--            　<img :src="form.endImg" height="40" width="40" />-->
<!--          </template>-->
<!--          <el-upload-->
<!--              :action="uploadImgUrl"-->
<!--              :limit="1"-->
<!--              :on-exceed="handleExceed"-->
<!--              :on-success="handleEndImgSuccess"-->
<!--              class="upload-demo"-->
<!--              multiple-->
<!--          >-->
<!--            <el-button size="small" type="primary">点击上传</el-button>-->
<!--            &lt;!&ndash;            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>&ndash;&gt;-->
<!--          </el-upload>-->
<!--          &lt;!&ndash;          <el-input v-model="form.addCode" placeholder="请输入引流二维码" />&ndash;&gt;-->
<!--        </el-form-item>-->

<!--        <el-form-item label="引流动图" prop="goCodeImg">-->
<!--          <el-input v-model="form.goCodeImg" placeholder="和本地上传二选一" />-->
<!--          <template>-->
<!--            　<img :src="form.goCodeImg" height="40" width="60" />-->
<!--          </template>-->
<!--          <el-upload-->
<!--              :action="uploadImgUrl"-->
<!--              :limit="1"-->
<!--              :on-exceed="handleExceed"-->
<!--              :on-success="handleAvatarSuccessGOCode"-->
<!--              class="upload-demo"-->
<!--              multiple-->
<!--          >-->
<!--            <el-button size="small" type="primary">点击上传</el-button>-->
<!--&lt;!&ndash;            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>&ndash;&gt;-->
<!--          </el-upload>-->
<!--          &lt;!&ndash;          <el-input v-model="form.addCode" placeholder="请输入引流二维码" />&ndash;&gt;-->
<!--        </el-form-item>-->

<!--        <el-form-item label="强制加群底部文案" prop="addText">-->
<!--          <el-input v-model="form.addText" placeholder="请输入强制加群底部文案" type="textarea" />-->
<!--        </el-form-item>-->
<!--        <el-form-item label="群聊名称" prop="groupName">-->
<!--          <el-input v-model="form.groupName" placeholder="请输入群聊名称" />-->
<!--        </el-form-item>-->
<!--        <el-form-item label="群聊所属微信" prop="groupWx">-->
<!--          <el-input v-model="form.groupWx" placeholder="请输入群聊所属微信" />-->
<!--        </el-form-item>-->


<!--        <el-form-item label="登录账户" prop="account">-->
<!--          <el-input v-model="form.account" placeholder="请输入登录账户" />-->
<!--        </el-form-item>-->
        <el-form-item label="管理员微信" prop="adminWx">
          <el-input v-model="form.adminWx" placeholder="请输入管理员微信" />
        </el-form-item>
        <el-form-item v-if="this.username=='root'" label="上架创作者平台（0否1是)" prop="distribution">
          <el-input v-model="form.distribution" placeholder="0否1是" />
        </el-form-item>
        <el-form-item v-if="this.username=='root'" label="所属账号" prop="accountId">
          <el-input v-model="form.accountId" placeholder="所属账号" />
        </el-form-item>
        <el-form-item label="主体" prop="belong">
          <el-input v-model="form.belong" placeholder="请输入主体" />
        </el-form-item>
        <el-form-item v-if="this.username=='root'" label="推广页面路径" prop="spreadPath">
          <el-input v-model="form.spreadPath" placeholder="请输入推广页面路径" />
        </el-form-item>

<!--        <el-form-item label="审核状态 如果审核就改为0" prop="belong">-->
<!--          <el-input v-model="form.status" placeholder="审核状态" />-->
<!--        </el-form-item>-->

        <el-form-item label="secret" prop="secret">
          <el-input v-model="form.secret" placeholder="secret" />
        </el-form-item>

        <el-form-item label="点击率（不填为不强点）" prop="secret">
          <el-input v-model="form.clickRate" placeholder="点击率（不填默认不强点）" />
        </el-form-item>

        <el-form-item label="第三方广告id" prop="audio">
          <el-input v-model="form.audio" placeholder="第三方广告id" />
        </el-form-item>

        <el-form-item label="是否开启第三方广告" prop="theme">
          <el-input v-model="form.theme" placeholder="开启填1（不填默认不开启第三方广告）" />
        </el-form-item>

        <el-form-item label="上线时间" prop="adoptTime">
<!--          <el-input v-model="form.adoptTime" placeholder="请输入上线时间" />-->
          <el-date-picker
              v-model="form.adoptTime"
              default-time="12:00:00"
              placeholder="上线时间"
              type="datetime">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

<!--    广告位配置-->
    <el-dialog
        title="广告位配置"
        :visible.sync="dialogVisibleAdConfig"
        width="70%"
        :before-close="handleClose">

      <el-table v-loading="loading" :data="adConfigList" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" align="center" />
<!--        <el-table-column label="主键" align="center" prop="id" />-->
<!--        <el-table-column label="小程序id" align="center" prop="appid" />-->
        <el-table-column label="广告位名称" align="center" prop="adName" />
        <el-table-column label="位置名称" align="center" prop="positionName" />
        <el-table-column label="位置标识" align="center" prop="positonFlag" />
        <el-table-column label="广告id" align="center" prop="adId" />
<!--        <el-table-column label="多久出现一次" align="center" prop="timeOnce" />-->
<!--        <el-table-column label="出现多少次" align="center" prop="timeNum" />-->
<!--        <el-table-column label="广告类型" align="center" prop="adType" />-->
        <el-table-column label="是否开启" align="center" prop="enable" >
          <template slot-scope="scoped">
            <el-switch
                v-model="scoped.row.enable"
                :active-value="1"
                :inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
                @change="changeInformationStatusAd($event, scoped.row, scoped.column)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="dialogVisibleAdIdInput(scope.row)"
            >修改</el-button>
<!--            <el-button-->
<!--                size="mini"-->
<!--                type="text"-->
<!--                icon="el-icon-delete"-->
<!--                @click="handleDelete(scope.row)"-->
<!--            >删除</el-button>-->
          </template>
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisibleAdConfig = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisibleAdConfig = false">确 定</el-button>
  </span>
    </el-dialog>

<!--    修改广告位-->
    <el-dialog
        title="请输入广告位ID"
        :visible.sync="dialogVisibleAdId"
        width="30%"
        :before-close="handleClose">

      <el-form ref="adForm" :model="adForm" label-width="80px">

        <el-form-item label="广告id" prop="adId">
          <el-input v-model="adForm.adId" placeholder="请输入广告id" />
        </el-form-item>

<!--        <el-form-item label="多久出现一次" prop="timeOnce" v-if="this.adForm.adType==1">-->
<!--          <el-input v-model="adForm.timeOnce" placeholder="请输入多久出现一次" />-->
<!--        </el-form-item>-->
<!--        <el-form-item label="出现多少次" prop="timeNum" v-if="this.adForm.adType==1">-->
<!--          <el-input v-model="adForm.timeNum" placeholder="请输入出现多少次" />-->
<!--        </el-form-item>-->
      </el-form>

      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisibleAdId = false">取 消</el-button>
    <el-button type="primary" @click="saveAdId()">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>

<script>
import { listWxapp, getWxapp, delWxapp, addWxapp, updateWxapp, exportWxapp,getListByAppid ,updateAdConfig,getAdConfig} from "@/api/work/Wxapp";

export default {
  name: "Wxapp",
  components: {
  },
  data() {
    return {
      uploadImgUrl: "https://api.sky1998.cn" + "/common/file/uploadToTencentOss", // 上传的图片服务器地址
      // 遮罩层
      loading: true,
      //主题色
      // color1: '#409EFF',
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 小程序管理表格数据
      WxappList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 主键字典
      idOptions: [],
      // 广告位配置管理表格数据
      adConfigList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        appid: null,
        name: null,
        type: null,
        account: null,
        admin: null,
        adminWx: null,
        belong: null,
        accountId: null,
        distribution: null,
        isAd: null,
        groupWx: null,
        spreadPath: null,
      },
      //广告位配置弹窗
      dialogVisibleAdConfig: false,
      //广告位输入框弹窗
      dialogVisibleAdId:false,
      //广告位表单
      adForm: {},
      username:null,
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        appid:[
          { required: true, message: '请输入小程序id', trigger: 'blur' },
        ],
        name:[
          { required: true, message: '请输入小程序名字', trigger: 'blur' },
        ],
      }
    };
  },
  created() {
    this.getList();
    this.getDicts("${column.dictType}").then(response => {
      this.idOptions = response.data;
    });
    this.username=Cookies.get('username');

  },
  methods: {

    //关闭小程序配置弹窗
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    //更新状态
    changeInformationStatus(value, { id }, { property }) {
      let parm=property

      let parms={
        id:id,
        [parm]:value
      }
      updateWxapp(
          parms
      )

    },

    //图片上传
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    handleAvatarSuccess(res, file) {
      this.form.addCode=res.datas
       console.log(res)
    },

    handleAvatarSuccessShareImg(res, file) {
      this.form.shareImg=res.datas
      console.log(res)
    },

    handleAvatarSuccessAudio(res, file) {
      this.form.audio=res.datas
      console.log(res)
    },

    handleAvatarSuccessGOCode(res, file) {
      this.form.goCodeImg=res.datas
      console.log(res)
    },
    handleBackImgSuccess(res, file) {
      this.form.backImg=res.datas
      console.log(res)
    },
    handleEndImgSuccess(res, file) {
      this.form.endImg=res.datas
      console.log(res)
    },

    /** 查询小程序管理列表 */
    getList() {
      this.loading = true;
      listWxapp(this.queryParams).then(response => {
        this.WxappList = response.datas;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 主键字典翻译
    idFormat(row, column) {
      return this.selectDictLabel(this.idOptions, row.id);
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        appid: null,
        name: null,
        type: null,
        account: null,
        admin: null,
        adminWx: null,
        belong: null,
        createTime: null,
        accountId: null,
        distribution: null,
        indexShowName: null,
        detailShowName: null,
        distanceToAppid: null,
        distanceToAdd: null,
        distanceToVad: null,
        isAd: null,
        theme: null,
        addCode: null,
        goCodeImg: null,
        addText: null,
        groupName: null,
        groupWx: null,
        adoptTime: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加小程序管理";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getWxapp(id).then(response => {
        this.form = response.datas;
        this.open = true;
        this.title = "修改小程序管理";
      });
    },
    /** 提交按钮 */
    submitForm() {

      let a = new Date(this.form.adoptTime).getTime();
      const date = new Date(a);
      const Y = date.getFullYear() + '-';
      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      const D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
      const h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
      const m = (date.getMinutes() <10 ? '0'+date.getMinutes() : date.getMinutes()) + ':' ;
      const s = date.getSeconds(); // 秒
      const dateString = Y + M + D + h + m + s;
      // console.log('dateString', dateString); // > dateString 2021-07-06 14:23
      this.form.adoptTime=dateString

      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updateWxapp(this.form).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addWxapp(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除小程序管理编号为"' + ids + '"的数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return delWxapp(ids);
        }).then(() => {
          this.getList();
          this.$modal.msgSuccess("删除成功");
        })
    },

    /** 获取小程序广告位列表 */
    adConfig(row) {
      getListByAppid({appid:row.appid}).then(res => {
        console.log(res.datas)
        this.adConfigList=res.datas
        this.dialogVisibleAdConfig=true
      });
    console.log(row)

    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有小程序管理数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return exportWxapp(queryParams);
        }).then(response => {
          this.download(response.msg);
        })
    },

    //修改广告位弹窗
    dialogVisibleAdIdInput(row){
      getAdConfig(row.id).then(response => {
        this.adForm = response.datas;
      });
      this.dialogVisibleAdId=true

    },

    //保存广告位
    saveAdId(){
      updateAdConfig(this.adForm).then(response => {
        this.$modal.msgSuccess("修改成功");
        this.open = false;
        console.log("****")
        console.log(this.adForm)
        console.log("****")

        getListByAppid({appid:this.adForm.appid}).then(res => {
          console.log(res.datas)
          this.adConfigList=res.datas
          this.dialogVisibleAdConfig=true
        });

        //this.adConfig(this.adForm.appid);
      });
      this.dialogVisibleAdId=false
    },

    //更新广告状态
    changeInformationStatusAd(value, { id }, { property }) {

      let parm=property

      let parms={
        id:id,
        [parm]:value
      }
      updateAdConfig(
          parms
      )

    },
  },


};
</script>
